
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig, inputList } from './config'
import { usePagination } from '@/hooks/usePagination'
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  watchEffect,
  ref,
} from 'vue'
import { required } from '@/utils/rules'
import { getList, add } from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: {
    CSearchList,
    CTable,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      titlename: '关联违章API',
      inputList: inputList(),
      ...getIndexConfig(),
      isType: '',
      isedit: false,
      currentSelection: [],
      formRef: undefined,
      formData: ref<any>({}),
      ids: [],
      rules: {
        num: [required],
      },
    })
    watchEffect(() => {
      data.visible = props.modelValue
    })

    /**
     * @description: 关闭dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      data.visible = false
      emit('update:modelValue', false)
      emit('getListFn')
    }
    const tableConfig = reactive({
      ...getTableConfig(),
    })

    const getListFn = async () => {
      const p = {
        ...data.queryForm,
        state: 1,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize,
      }

      tableConfig.loading = true
      const [err, res] = await toResult(getList(p))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total
    }

    // 查询
    const handleSearch = (params) => {
      tableConfig.pagination.currentPage = 1
      params && (data.queryForm = { ...params })
      getListFn()
    }

    const onToggle = (name, row?) => {
      data[name] = !data[name]
      row && (data.rowData = row)
    }

    // 重置
    const handleReset = (val) => {
      data.queryForm = val
    }

    const onConfirm = () => {
      if (data.ids.length === 0) {
        ElMessage({
          showClose: true,
          message: '请选择',
          type: 'warning',
        })
        return
      }
      data.formRef.validate(async (valid) => {
        if (valid) {
          const p = {
            companyUuid: props.rowData.uuid,
            unitPrice: data.formData.num,
            ids: data.ids,
          }
          await add(p)
          ElMessage({
            type: 'success',
            message: '操作成功',
          })
          onCancel()
        }
      })
    }

    //表格多选
    const selectionChange = (item) => {
      data.currentSelection = []
      data.ids = []
      item.forEach(() => {
        data.currentSelection = [...new Set(data.currentSelection.concat(item))]
      })
      data.currentSelection.forEach((i) => {
        data.ids.push(i.id)
      })
    }

    const { pageCurrentChange, pageSizeChange } = usePagination(
      tableConfig.pagination,
      () => {
        getListFn()
      }
    )

    onMounted(() => {
      handleSearch({})
    })

    return {
      ...toRefs(data),
      handleSearch,
      btnName,
      handleReset,
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      getListFn,
      onToggle,
      onCancel,
      onConfirm,
      selectionChange,
    }
  },
})
