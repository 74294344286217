import { enumToOption } from '@/utils/index'
import { ElTag } from 'element-plus'
import { h } from 'vue'

enum enterpriseStatus {
  正常 =1,
  禁用
}

const enterpriseStatusOption = enumToOption(enterpriseStatus)

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: true,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: false,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [

    {
      key: 'enterpriseCode',
      label: '企业编号',
      width: '140'
    },

    {
      key: 'name',
      label: '企业名称',
      width: '140'
    },
    {
      key: 'enterpriseStatus',
      label: '状态',
      width: '140',
      formatter: (row, col, val) => {
        return h(ElTag, {
          size: 'medium',
          type: val === 1 ? 'success' : 'danger'
        }, enterpriseStatus[val])
      }
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '企业名称',
      key: 'name',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      rules: {
        min: 2,
        max: 32,
        message: '字符长度必须在2-32位之间'
      }
    },
    {
      type: 'select',
      label: '状态',
      key: 'enterpriseStatus',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enterpriseStatusOption,
      rules: {
        type: 'number'
      }
    }

  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
