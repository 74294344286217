
import { defineComponent, reactive, toRefs, onMounted, computed } from 'vue'
// import { detailOfAccountPermission, PermissionUpdateRole } from './api'
// import { treeData } from '@/utils/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { toResult } from '@/utils/toResult'
import { getRoleList, getPresetRole } from './api'
export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const dialogVisible = computed(() => props.visible)
    const data = reactive({
      tree: null,
      form: {
        role: '',
        enterpriseCode: '',
        enterpriseName: '',
        accountName: '',
        checkedCities: []
      },

      formRef: null,
      rules: {
        checkedCities: [{
          required: true,
          message: '必填',
          trigger: 'change'
        }]
      },
      cities: [],
      presetRoleName: '',
      options: [],
      keys: [],
      data: []
    })
    const handleClose = () => {
      // data.keys = []
      // dialogVisible.value = false

      emit('update:visible', false)
      data.formRef.resetFields()
    }

    const getPresetRoleFn = async() => {
      const [err, res] = await toResult(getPresetRole({
        platformCode: 2,
        companyUuid: props.rowData.uuid,
        roleIdList: data.form.checkedCities
      }))
      if (err) return false
      ElMessage.success('配置成功！')
      handleClose()
      console.log(res)
    }
    const handleConfirm = () => {
      ElMessageBox.confirm(
        '确定预置角色吗',
        '提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          getPresetRoleFn()
          ElMessage({
            type: 'success',
            message: '成功'
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '取消'
          })
        })
    }
    const handleSubmit = () => {
      data.formRef.validate((valid) => {
        if (!valid) {
          return false
        }
        handleConfirm()
      })
      // 勾选参数
      // const permissions = []
      // data.tree.getCheckedNodes().forEach(item => {
      //   permissions.push({
      //     type: item.type,
      //     menuId: item.parentId, // 父级id
      //     menuOperationId: item.id // 自身id
      //   })
      // })
      // PermissionUpdateRole({
      //   accountId: props.rowData.accountId,
      //   permissions: JSON.stringify(permissions)
      // }).then((res: any) => {
      //   ElMessage.success(res.msg)
      //   dialogVisible.value = false
      // })
    }

    const getDetail = async(row) => {
      const [err, res] = await toResult(getRoleList({
        presetRole: 1,
        roleType: 2,
        platformCode: 2,
        companyId: row.uuid
      }))
      if (err) return false
      const { voList, presetRoleName, enterpriseId } = res
      console.log(enterpriseId, row.id, enterpriseId === row.id)
      data.form.enterpriseCode = res.enterpriseCode
      data.form.enterpriseName = res.enterpriseName
      data.form.accountName = res.accountName
      data.cities = voList as object[]
      data.presetRoleName = presetRoleName
    }

    onMounted(() => {
      getDetail(props.rowData)
    })
    return {
      dialogVisible,
      ...toRefs(data),
      handleSubmit,
      handleClose
    }
  }
})
