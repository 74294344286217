
import { defineComponent, reactive, ref, toRefs } from 'vue'
import CTable from '@/components/c-table/index.vue'
import {
  getListAPI,
  bankConfigAPI
} from '@/views/singlePage/system-config/backend-collection/api'
import { toResult } from '@/utils/toResult'
import {
  bankType,
  isDefault
} from '@/views/singlePage/system-config/backend-collection/config'
import { ElMessage } from 'element-plus'
const tableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    height: 300,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false
  }

  // table 列数据
  const column = [
    {
      slot: 'selection',
      label: '选择',
      align: 'center',
      width: '30'
    },
    {
      key: 'bankType',
      label: '合作银行渠道',
      width: '100',
      formatter: (row, col, val) => bankType[val]
    },
    {
      key: 'appIdName',
      label: '名称',
      width: '100'
    },
    {
      key: 'appId',
      label: 'APPID',
      width: '110'
    },
    {
      key: 'isDefault',
      label: '自动默认',
      width: '40',
      formatter: (row, col, val) => isDefault[val]
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  components: { CTable },
  setup(props, { emit }) {
    console.log(props.rowData)
    const data = reactive({
      dialogVisible: props.visible,
      dataSource: tableConfig(),
      currentRow: ref<any>([])
    })

    const getList = async () => {
      data.dataSource.loading = true
      const [err, res] = await toResult(getListAPI({ isDefult: 2 }))
      data.dataSource.loading = false
      if (err) return
      console.log(res)
      data.dataSource.data = res || []
    }

    getList()

    const handleClose = () => {
      emit('update:visible', false)
    }

    // 设置单选
    const handleSelection = ({ row }) => {
      let isAdd = true
      if (row.isSelection) {
        // 选择添加
        if (data.currentRow.length === 0) {
          data.currentRow.push(row)
        } else {
          data.currentRow.forEach(item => {
            if (item.bankType === row.bankType) {
              ElMessage.warning('同银行仅可指定一个')
              row.isSelection = false
              isAdd = false
            }
          })
          if (isAdd) data.currentRow.push(row)
        }
      } else {
        // 取消勾选
        const index = data.currentRow.findIndex((item) => item.id === row.id)
        data.currentRow.splice(index, 1)
      }
    }

    // 保存提交
    const handleSubmit = async () => {
      const bankRelationId = data.currentRow.map(item => {
        return item.id
      })
      const p = {
        bankRelationId: bankRelationId,
        companyUuid: props.rowData.uuid
      }

      const [err, res, msg] = await toResult(bankConfigAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success(msg || '成功')
      emit('getListFn')
      handleClose()
    }

    return {
      ...toRefs(data),
      handleClose,
      handleSubmit,
      handleSelection
    }
  }
})
