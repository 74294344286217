
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { required } from '@/utils/rules'
import CSearchList from '@/components/c-search-list/index.vue'
import CTable from '@/components/c-table/index.vue'
import { getComboListAPI, recordAddtAPI } from './api'
import { toResult } from '@/utils/toResult'
import { usePagination } from '@/hooks/usePagination'
import { ElMessage, ElMessageBox } from 'element-plus'
import moment from 'moment'

const inputList = [
  {
    type: 'input',
    label: '套餐编号',
    key: 'uuid',
    placeholder: '请输入',
    labelWidth: 80,
    inputWidth: 200
  },
  {
    type: 'input',
    label: '标题',
    key: 'title',
    placeholder: '请输入',
    labelWidth: 60,
    inputWidth: 200
  }
]

export enum isCompel {
  强制付费 = 1,
  可暂不付费
}

const tableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: true,
    // table loading
    loading: false,
    height: 300,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'uuid',
      label: '套餐编号',
      width: '160'
    },
    {
      key: 'title',
      label: '套餐标题',
      width: '100'
    },
    {
      key: 'roleName',
      label: '试用功能权限',
      width: '110'
    },
    {
      key: 'newRoleName',
      label: '新功能版本',
      width: '110'
    },
    {
      key: 'trialPeriod',
      label: '试用期(天)',
      width: '80'
    },
    {
      key: 'formalPeriod',
      label: '正式期(天)',
      width: '80'
    },
    {
      key: 'price',
      label: '单车费用（天/元)',
      width: '140'
    },
    {
      key: 'basicAnnualFee',
      label: '基础年费(元)',
      width: '100'
    },
    {
      key: 'carChargeAnnualFee',
      label: '单车收费年费',
      width: '140'
    },
    {
      key: 'carBasicAnnualFee',
      label: '单车基础年费',
      width: '110'
    },
    {
      key: 'carChargeDailyFee',
      label: '单车收费（天/元）',
      width: '140'
    },
    {
      key: 'withholdingRate',
      label: '代扣费率',
      width: '110',
      formatter: (row, col, val) => { return val || '-' }
    },
    {
      key: 'isCompel',
      label: '试用结束后',
      width: '90',
      formatter: (row, col, val) => { return isCompel[val] || '-' }
    },
    {
      key: 'description',
      label: '描述',
      width: '180',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '150'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  components: { CSearchList, CTable },
  setup(props, { emit }) {
    console.log(props.rowData)
    const data = reactive({
      dialogVisible: props.visible,
      inputList,
      dataSource: tableConfig(),
      form: ref<any>({}),
      formDataRef: ref<HTMLElement | any>(null),
      formData: ref<any>({}),
      rules: {
        signTime: [required],
        deductStartTime: [required]
      },
      // ref dom
      tableSelectAllRef: ref<HTMLElement | null>(null),
      selectionCurrent: ref<any>([])
    })

    watch(() => data.formData.signTime, val => {
      if (val) {
        data.formData.trialEndTime = moment(val).add(data.selectionCurrent.trialPeriod, 'days')
        data.formData.formalEndTime = moment(val).add(data.selectionCurrent.formalPeriod, 'days')
      } else {
        data.formData.trialEndTime = ''
        data.formData.formalEndTime = ''
      }
    })

    const getList = async () => {
      data.dataSource.loading = true
      const p = {
        currPage: data.dataSource.pagination.currentPage,
        pageSize: data.dataSource.pagination.pageSize,
        // 状态（0-禁用，1-启用）只显示启用状态
        enabled: 1,
        ...data.form
      }
      const [err, res] = await toResult(getComboListAPI(p))
      data.dataSource.loading = false
      if (err) return
      console.log(res)
      data.dataSource.data = res.list || []
      console.log(data.dataSource.data)
      data.dataSource.pagination.total = res.total
    }

    const handleSearch = (params?) => {
      data.dataSource.pagination.currentPage = 1
      data.form = Object.assign(data.form, params)
      getList()
    }

    handleSearch()

    /**
     * @description: 当选择项发生变化时会触发该事件
     * @param {*} selection
     * @return {void}
     */
    const selectionChange = (selection: any[]) => {
      const ref: any = data.tableSelectAllRef
      if (selection.length > 1) {
        ElMessage.warning('只能勾选一个套餐')
        ref.cTableRef.clearSelection()
        data.formData.signTime = ''
        data.formData.deductStartTime = ''
        return
      }
      data.selectionCurrent = selection[0]
      console.log(data.selectionCurrent)
      const currentDate = moment()
      data.formData.signTime = currentDate
      data.formData.deductStartTime = currentDate
    }

    const handleReset = val => {
      data.form = val
    }

    const handleClose = () => {
      emit('update:visible', false)
    //   data.formRef.resetFields()
    }

    // 提交请求发送
    const sendRequset = async () => {
      const format = 'YYYY-MM-DD'
      const p = {
        companyUuid: props.rowData.uuid,
        serviceMealUuid: data.selectionCurrent.uuid,
        signTime: moment(data.formData.signTime).format(format),
        deductStartTime: moment(data.formData.deductStartTime).format(format),
        formalEndTime: moment(data.formData.formalEndTime).format(format),
        trialEndTime: moment(data.formData.trialEndTime).format(format)
      }
      const [err, res] = await toResult(recordAddtAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success('添加成功')
      handleClose()
      emit('getListFn')
    }

    // 保存提交
    const handleSubmit = () => {
      data.formDataRef.validate(valid => {
        if (!valid) return false
        if (moment(data.formData.deductStartTime).diff(moment(data.formData.signTime)) < 0) {
          ElMessage.warning('扣费开始日期必须要大于或等于开通日期')
          return false
        }
        ElMessageBox.confirm(
          '确定套餐配置操作吗？请仔细核对套餐金额',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          sendRequset()
        })
      })
    }
    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      data.dataSource.pagination,
      () => { getList() }
    )
    return {
      ...toRefs(data),
      handleClose,
      handleSubmit,
      handleSearch,
      handleReset,
      pageCurrentChange,
      pageSizeChange,
      selectionChange
    }
  }
})
