
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { affiliatedEnterprisePageAPI, affiliatedAPI } from './api'
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import {
  getTableConfig,
  getIndexConfig
} from './affiliated-subsidiaries-config'
export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  components: {
    CSearchList,
    CTable
  },
  setup(props, { emit }) {
    const data = reactive({
      ...getIndexConfig(),
      dialogVisible: props.visible,
      currentSelection: []
    })

    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    const handleClose = () => {
      emit('update:visible', false)
    }

    const getListFn = () => {
      const p = {
        ...data.queryForm,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      affiliatedEnterprisePageAPI(p).then(res => {
        tableConfig.data = res.data.data.list
        tableConfig.pagination.total = res.data.data.total
      })
    }

    // 选中规格
    const selectionChange = item => {
      data.currentSelection = item
      console.log('选择', data.currentSelection)
    }

    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }

    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    const handleReset = val => {
      data.queryForm = val
      getListFn()
    }
    const handleSearch = (params: object) => {
      data.queryForm = Object.assign({}, params)
      getListFn()
    }

    const handleSubmit = () => {
      const list = data.currentSelection.map(item => item.uuid)
      const p = {
        uuid: props.rowData.uuid,
        list: list
      }
      affiliatedAPI(p).then(() => {
        ElMessage({
          type: 'success',
          message: '操作成功'
        })
        handleClose()
        emit('getListFn')
      })
    }

    onMounted(() => {
      getListFn()
    })

    return {
      tableConfig,
      ...toRefs(data),
      pageCurrentChange,
      pageSizeChange,
      selectionChange,
      getListFn,
      handleReset,
      handleSearch,
      handleSubmit,
      handleClose
    }
  }
})
