
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch,
  computed,
} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { required, numberAndDecimalPint } from '@/utils/rules'
import { paymentAdd, detailOfPayment } from './api'
import { enumToOption, digitUppercase } from '@/utils/index'
import dayjs from 'dayjs'

enum payChannel {
  '支付宝' = 1,
  '微信',
  '工商',
  '银行汇款',
  '系统',
}

const checkServiceMealPayAmount = (rule: any, value: any, callback: any) => {
  if (value > 500000) {
    callback(new Error('实缴金额范围0-50万'))
  } else {
    callback()
  }
}

const checkCarPayAmount = (rule: any, value: any, callback: any) => {
  if (value > 500000) {
    callback(new Error('实缴金额范围0-50万'))
  } else {
    callback()
  }
}

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object,
  },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({
        checkList: [],
        // 初始缴费：默认是
        initialType: 1,
        // 缴费类型：默认年
        paymentType: 1,
        // 缴费状态：默认待缴费
        state: 0,
        carPayAmount: '0.00',
        payableAmount: 0.0,
        a: '',
        b: '',
      }),
      // 单车基础费每天
      basicCarDailyAmount: 0,
      // 单车基础费每年
      basicCarYearAmount: 0,
      // 单车收费费每天
      chargeCarDailyAmount: 0,
      // 单车收费费每年
      chargeCarYearAmount: 0,
      formRef: null,
      payChannelOption: enumToOption(payChannel), //  缴费渠道
      internalRemarkplaceholder: '请输入',
      payRemarkplaceholder: '前台显示',
    })
    const bindCheckBox = () => {
      if (data.form.checkList.length > 1) {
        data.form.checkList.splice(0, 1)
      }
    }

    const checkCarGivingMoney = (rule: any, value: any, callback: any) => {
      // if (value > 100000) {
      //   callback(new Error('优惠金额范围0-100000'))
      // } else
      console.log(value, data.form.payableAmount)
      if (Number(value) > Number(data.form.payableAmount || 0)) {
        callback(new Error('优惠金额不能大于应缴总金额'))
      } else {
        callback()
      }
    }

    const rules = ref<any>({
      checkList: [required],
      payChannel: [required],
      payTime: [required],
      paymentType: [required],
      paymentPeriod: [
        { required: true, message: '必填', trigger: ['blur', 'change'] },
        {
          pattern: /^([1-9]|10)$/,
          message: '只支持输入1-10',
          trigger: ['blur', 'change'],
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > 10) {
              callback(new Error('只支持输入1-10'))
            } else {
              callback()
            }
          },
          trigger: ['blur', 'change'],
        },
      ],
      serviceMealPayAmount: [
        required,
        numberAndDecimalPint,
        { validator: checkServiceMealPayAmount, trigger: ['blur', 'change'] },
      ],
      carPayAmount: [
        required,
        numberAndDecimalPint,
        { validator: checkCarPayAmount, trigger: ['blur', 'change'] },
      ],
      carGivingMoney: [
        numberAndDecimalPint,
        { validator: checkCarGivingMoney, trigger: ['blur', 'change'] },
      ],
      carBaseNum: [
        required,
        {
          pattern: /^(([0-9][0-9]*))$/,
          message: '只支持输入0-90000',
          trigger: 'blur',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > 90000) {
              callback(new Error('不能大于90000'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
      carChargeNum: [
        required,
        {
          pattern: /^(([0-9][0-9]*))$/,
          message: '只支持输入0-90000',
          trigger: 'blur',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > 90000) {
              callback(new Error('不能大于90000'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
      payableAmount: [required, numberAndDecimalPint],
      state: [required, numberAndDecimalPint],
      a: [
        required,
        {
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入数字'))
            }
            if (value < 1 || value > 99999) {
              callback(new Error('输入的数字必须在1到99999之间'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
      b: [
        required,
        {
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入数字'))
            }
            if (value < 0 || value > 99999) {
              callback(new Error('输入的数字必须在0到99999之间'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        {
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入数字'))
            }
            if (Number(value) > Number(data.form.a)) {
              callback(new Error('优惠金额不能大于应缴总金额'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
    })

    const handleClose = () => {
      emit('update:visible', false)
      data.formRef.resetFields()
    }
    // 二次确定
    const handleConfirm = () => {
      ElMessageBox.confirm('确定缴费吗', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = {
          companyUuid: props.rowData.uuid,
          serviceMealPayAmount: Number(data.form.serviceMealPayAmount),
          payChannel: data.form.payChannel,
          payTime: dayjs(data.form.payTime).format('YYYY-MM-DD HH:mm:ss'),
          internalRemark: data.form.internalRemark,
          payRemark: data.form.payRemark,
          carPayAmount: Number(data.form.carPayAmount),
          carGivingMoney: Number(data.form.carGivingMoney),

          initialType: data.form.initialType,
          carBaseNum: Number(data.form.carBaseNum),
          carChargeNum: Number(data.form.carChargeNum),
          paymentType: data.form.paymentType,
          paymentPeriod: Number(data.form.paymentPeriod),
          payableAmount: Number(data.form.payableAmount),
          state: Number(data.form.state),
          feeType: 1,
        }
        if (data.form.state === 0) {
          delete params.payTime
        }
        if (data.form.checkList.indexOf('增值服务费') !== -1) {
          console.log('增值服务费')

          params.payableAmount = Number(data.form.a)
          params.carGivingMoney = Number(data.form.b)
          params.carPayAmount = Number(data.form.a - data.form.b)
          params.feeType = 3
          params.carBaseNum = 0
          params.carChargeNum = 0
          params.paymentPeriod = 0
          params.serviceMealPayAmount = 0
          params.paymentType = 3
        }
        paymentAdd(params).then(() => {
          ElMessage({
            type: 'success',
            message: '成功',
          })
          handleClose()
          emit('getListFn')
        })
      })
    }

    // 保存提交
    const handleSubmit = () => {
      data.formRef.validate((valid) => {
        if (!valid) {
          return false
        }
        if (
          Number(data.form.carBaseNum) + Number(data.form.carChargeNum) <=
          0
        ) {
          ElMessage.error('收费辆数和车辆数两个相加必须大于0')
          return false
        }
        handleConfirm()
      })
    }

    const changePaymentType = (val) => {
      data.formRef.validate((valid) => {
        if (!valid) {
          return false
        }
      })
      console.log(val)
      data.form.paymentPeriod = ''
      if (val === 1) {
        rules.value.paymentPeriod = [
          { required: true, message: '必填', trigger: ['blur', 'change'] },
          {
            pattern: /^([1-9]|10)$/,
            message: '只支持输入1-10',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule: any, value: any, callback: any) => {
              if (Number(value) > 10) {
                callback(new Error('只支持输入1-10'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change'],
          },
        ]
      } else {
        rules.value.paymentPeriod = [
          { required: true, message: '必填', trigger: ['blur', 'change'] },
          {
            pattern: /^(([1-9][0-9]*))$/,
            message: '只支持输入1-3650',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule: any, value: any, callback: any) => {
              console.log(value)
              if (!Number.isInteger(value) && Number(value) > 3650) {
                callback(new Error('只支持输入1-3650'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change'],
          },
        ]
      }
    }

    watch(data.form, () => {
      // 应缴总金额
      let p1 = 0
      let p2 = 0
      // 年
      // 应缴总金额 = （基础车辆数 * 单车基础年费 * 年限） + （收费车辆数 * 单车收费年费*年限 ）应缴总金额，不可修改
      if (data.form.paymentType === 1) {
        p1 =
          Number(data.form.carBaseNum || 0) *
          Number(data.basicCarYearAmount) *
          Number(data.form.paymentPeriod || 0)
        p2 =
          Number(data.form.carChargeNum || 0) *
          Number(data.chargeCarYearAmount) *
          Number(data.form.paymentPeriod || 0)
      }
      // 天
      if (data.form.paymentType === 2) {
        p1 =
          Number(data.form.carBaseNum || 0) *
          Number(data.basicCarDailyAmount) *
          Number(data.form.paymentPeriod || 0)
        p2 =
          Number(data.form.carChargeNum || 0) *
          Number(data.chargeCarDailyAmount) *
          Number(data.form.paymentPeriod || 0)
      }
      data.form.payableAmount = (p1 + p2).toFixed(2) || 0
      // 优惠金额：不大于 应缴总金额  仅数字（ 改为 非必填）（排版移至实缴金额上面）
    })

    // 实缴金额 = 应缴总金额 - 优惠金额 ，不可为空，仅数字，默认等于应缴总金额。
    // watchEffect(() => {
    //   data.form.carPayAmount = (Number(data.form.payableAmount || 0) - Number(data.form.carGivingMoney || 0)) || 0
    //   data.form.carPayAmount = (Number(data.form.carPayAmount) || 0).toFixed(2)
    // })

    const carPayAmountChange = () => {
      console.log(data.form.payableAmount)
      // 实缴金额 = 应缴总金额 - 优惠金额 ，不可为空，仅数字，默认等于应缴总金额。
      data.form.carPayAmount =
        Number(data.form.payableAmount || 0) -
          Number(data.form.carGivingMoney || 0) || 0
      data.form.carPayAmount = (Number(data.form.carPayAmount) || 0).toFixed(2)
    }

    watch(
      () => data.form.payableAmount,
      (val) => {
        console.log(val)
        carPayAmountChange()
      },
      { deep: true }
    )

    data.form.sdje = computed(() => {
      // 账户实到金额：  实缴金额 - 欠费金额 （当前不会产生欠费）
      const carPayAmount = data.form.carPayAmount || 0
      return (Number(carPayAmount) - Number(data.form.owingMoney)).toFixed(2)
    })

    const changePayChannel = (val) => {
      if (val === 0) {
        data.payRemarkplaceholder = '前台显示'
        data.internalRemarkplaceholder = '请输入'
      } else {
        data.payRemarkplaceholder = '前台显示'
        data.internalRemarkplaceholder = '请填写交易流水号或说明'
        rules.value.internalRemark = [required]
      }
    }

    onMounted(() => {
      detailOfPayment({ companyUuid: props.rowData.uuid }).then((res) => {
        data.form.presetRoleName = res.data.basicAnnualFee
        data.form.owingMoney = res.data.owingMoney
        data.basicCarDailyAmount = res.data.basicCarDailyAmount || 0
        data.basicCarYearAmount = res.data.basicCarYearAmount || 0
        data.chargeCarDailyAmount = res.data.chargeCarDailyAmount || 0
        data.chargeCarYearAmount = res.data.chargeCarYearAmount || 0
      })
    })

    return {
      ...toRefs(data),
      rules,
      handleClose,
      handleSubmit,
      digitUppercase,
      changePaymentType,
      changePayChannel,
      carPayAmountChange,
      bindCheckBox,
    }
  },
})
