/*
 * @Descripttion:
 * @Author: panyufeng
 * @Date: 2024-09-29 15:27:43
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-10-10 10:40:19
 */

import service from '@/utils/request'

// 列表数据查询
export const getList = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/list',
    data: params
  })

// 违章转关联
export const add = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSettingCorrelation/add',
    data: params
  })
